@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top {
  /* Arrows */
  /* Dots */ }
  #top .p-mainVisual {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual {
        height: 507px; } }
    #top .p-mainVisual__bg {
      width: 100%;
      height: 100%;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative; }
    #top .p-mainVisual__swipe {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: calc(436 / 1063 * 100%);
      background-color: #FFFFFF;
      line-height: 0; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__swipe {
          top: 23px;
          transform: none;
          width: 296px; } }
      #top .p-mainVisual__swipe-inner {
        width: 100%;
        height: 100%; }
      #top .p-mainVisual__swipe-wrap {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: -125px; }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__swipe-wrap {
            right: -30px; } }
      #top .p-mainVisual__swipe-item {
        width: 100%;
        height: 23.6vw;
        max-height: 500px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__swipe-item {
            height: 205px; } }
    #top .p-mainVisual__catch {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 1254px;
      width: 100%;
      color: #FFFFFF;
      padding: 0 10px; }
      #top .p-mainVisual__catch img {
        width: 100%; }
      @media screen and (max-width: 1024px) {
        #top .p-mainVisual__catch {
          max-width: 460px;
          top: 70%; } }
      @media screen and (max-width: 568px) {
        #top .p-mainVisual__catch {
          padding: 0 15px 0 30px; } }
      #top .p-mainVisual__catch-heading {
        font-size: 48px;
        margin-bottom: 18px; }
        @media screen and (max-width: 1024px) {
          #top .p-mainVisual__catch-heading {
            font-size: 36px; } }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__catch-heading {
            font-size: 28px; } }
        #top .p-mainVisual__catch-heading small {
          font-size: 32px; }
          @media screen and (max-width: 1024px) {
            #top .p-mainVisual__catch-heading small {
              font-size: 26px; } }
          @media screen and (max-width: 768px) {
            #top .p-mainVisual__catch-heading small {
              font-size: 22px; } }
      #top .p-mainVisual__catch-desc {
        font-size: 18px;
        line-height: 1.5; }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__catch-desc {
            font-size: 16px; } }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__catch-desc {
            font-size: 14px; } }
    #top .p-mainVisual__scroll {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 105px;
      right: -55px;
      transform: rotate(90deg); }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__scroll {
          bottom: 125px;
          right: -68px; } }
      #top .p-mainVisual__scroll-txt {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.05em;
        display: inline-block;
        margin-right: 12px; }
      #top .p-mainVisual__scroll-bar {
        display: inline-block;
        width: 100px;
        height: 2px;
        background-color: #CCCCCC;
        position: relative;
        overflow: hidden; }
        #top .p-mainVisual__scroll-bar:before {
          content: '';
          width: 50%;
          height: 2px;
          position: absolute;
          top: 0;
          left: -50%;
          display: inline-block;
          background-color: #FF7001; }
  #top #wrapper {
    padding: 0 0;
    padding-top: 60px; }
    @media screen and (max-width: 1168px) {
      #top #wrapper {
        padding: 60px 0 0; } }
  #top #newslist {
    padding-bottom: 0; }
    #top #newslist .c-newslist {
      display: block !important; }
      @media screen and (max-width: 768px) {
        #top #newslist .c-newslist__wrap {
          background-color: #F7F7F7;
          padding: 15px 0 30px; } }
      @media screen and (max-width: 768px) {
        #top #newslist .c-newslist dl dt {
          margin-bottom: 5px; } }
      #top #newslist .c-newslist dl dd {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  #top .p-bar-position {
    display: block;
    line-height: 10px;
    max-width: 48px;
    margin: 0 auto;
    padding-top: 15px;
    margin-bottom: 20px; }
    #top .p-bar-position img {
      width: 100%; }
  #top .p-experience-title {
    text-align: center;
    color: #FFFFFF;
    font-size: 24px;
    background-position-y: 100px;
    padding-bottom: 30px;
    background-size: 290px; }
    @media screen and (max-width: 768px) {
      #top .p-experience-title {
        background-size: 270px;
        font-size: 24px; } }
    #top .p-experience-title span {
      display: block;
      margin-bottom: 10px; }
  #top .about-slider-list {
    display: flex;
    margin: auto; }
  #top .about-slider-item {
    display: block;
    height: auto;
    border-radius: 10px;
    margin: 0 10px 0; }
    @media screen and (max-width: 568px) {
      #top .about-slider-item {
        width: 25vw; } }
  #top .about-slider-item img {
    border-radius: 10px; }
  #top .slick-prev,
  #top .slick-next {
    z-index: 1000;
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none; }
    #top .slick-prev:hover, #top .slick-prev:focus,
    #top .slick-next:hover,
    #top .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      #top .slick-prev:hover:before, #top .slick-prev:focus:before,
      #top .slick-next:hover:before,
      #top .slick-next:focus:before {
        opacity: 1; }
    #top .slick-prev.slick-disabled:before,
    #top .slick-next.slick-disabled:before {
      opacity: 0.25; }
    #top .slick-prev:before,
    #top .slick-next:before {
      font-family: "slick";
      font-size: 25px;
      line-height: 1;
      color: #FF7001;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  #top .slick-prev {
    left: 16.5%; }
    @media screen and (max-width: 568px) {
      #top .slick-prev {
        left: 6.5%; } }
    [dir="rtl"] #top .slick-prev {
      left: auto;
      right: 16.5%; }
      @media screen and (max-width: 568px) {
        [dir="rtl"] #top .slick-prev {
          right: 6.5%; } }
    #top .slick-prev:before {
      content: "←"; }
      [dir="rtl"] #top .slick-prev:before {
        content: "→"; }
  #top .slick-next {
    right: 17%; }
    @media screen and (max-width: 568px) {
      #top .slick-next {
        right: 6%; } }
    [dir="rtl"] #top .slick-next {
      left: 17%;
      right: auto; }
      @media screen and (max-width: 568px) {
        [dir="rtl"] #top .slick-next {
          left: 6%; } }
    #top .slick-next:before {
      content: "→"; }
      [dir="rtl"] #top .slick-next:before {
        content: "←"; }
  #top .slick-dotted.slick-slider {
    margin-bottom: 30px; }
  #top .slick-dots {
    position: absolute;
    bottom: 75px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%; }
    @media screen and (max-width: 768px) {
      #top .slick-dots {
        bottom: -10px; } }
    #top .slick-dots li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      #top .slick-dots li button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        #top .slick-dots li button:hover, #top .slick-dots li button:focus {
          outline: none; }
          #top .slick-dots li button:hover:before, #top .slick-dots li button:focus:before {
            opacity: 1; }
        #top .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "•";
          width: 20px;
          height: 20px;
          font-family: "slick";
          font-size: 6px;
          line-height: 20px;
          text-align: center;
          color: black;
          opacity: 0.25;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
          @media screen and (max-width: 1024px) {
            #top .slick-dots li button:before {
              font-size: 12px; } }
      #top .slick-dots li.slick-active button:before {
        color: black;
        opacity: 0.75; }
  #top .p-l-section-about {
    padding-top: 0 !important; }
  #top .p-bg-title-about {
    background-image: url("/inc/image/top/img_bg_about.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 260px;
    padding-top: 34px; }
    @media screen and (max-width: 600px) {
      #top .p-bg-title-about {
        background-position-y: 12px;
        background-size: 284px;
        padding-top: 33px; } }
  #top .p-bg-title-experience {
    background-image: url("/inc/image/top/img_bg_experience.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-y: 8px;
    background-size: 431px;
    padding-top: 35px; }
    @media screen and (max-width: 600px) {
      #top .p-bg-title-experience {
        background-size: 345px;
        background-position-y: 14px; } }
  #top .p-bg-title-activity {
    background-image: url("/inc/image/top/img_bg_activity.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-y: 3px;
    background-size: 335px;
    padding-top: 35px; }
  #top .p-bg-title-stay {
    background-image: url("/inc/image/top/img_bg_stay.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-y: 5px;
    background-size: 190px;
    padding-top: 35px; }
  #top .p-stay-title {
    margin-bottom: 30px; }
  #top .p-stay-span {
    display: block;
    text-align: center;
    margin-bottom: -10px; }
  @media screen and (max-width: 768px) {
    #top .p-firstBox-mb {
      margin-bottom: 60px; } }
  #top .p-sp-none {
    display: none; }
    @media screen and (max-width: 600px) {
      #top .p-sp-none {
        display: block; } }
  #top .p-about__img {
    margin-top: 30px; }
  #top .p-about__text {
    padding-bottom: 30px; }
  #top .p-experience {
    background-image: url("/inc/image/top/bg_experience.jpg");
    background-size: cover;
    color: #FFFFFF;
    padding: 0 0 150px;
    background-position: center;
    margin-bottom: 90px; }
  #top .p-activity__img {
    margin-top: 30px;
    display: block;
    width: 100%; }
  #top .p-stay {
    padding-top: 0px; }
    #top .p-stay__img {
      margin-top: 30px;
      padding: 0 12px; }
      #top .p-stay__img > img {
        width: 100%; }
      #top .p-stay__img__item {
        padding: 3px; }
  #top .p-lineH {
    line-height: 1.6; }
  #top .fixtures {
    padding: 30px;
    background: #f9f9f9; }
    #top .fixtures ul {
      margin-top: 30px;
      margin-bottom: 20px; }
      #top .fixtures ul li {
        display: inline-block;
        margin: 0 15px 10px; }
  #top .p-mb15 {
    margin-bottom: 15px; }
